import React, { Component } from "react";
import { connect } from "react-redux";
// import baadActions from '../../redux/actions/';
// import HomeLink from '../HomeLink/homeLink';
import "./humanActivities.scss";
import baadActions from "../../redux/actions/";
//import PanelFooter from '../panelFooter/panelFooter';
//import constants from "../../utils/appConstant";
import Navigation from "../navigation/Navigation";
import AboutPage from "../aboutPage/aboutPage";
import Footer from "../panelFooter/Footer";
import MobileOverlay from "../../components/MobileOverlay/MobileOverlay";
import { HelmetHeader } from "../../components/HelmetHeader/HelmetHeader";
//import { CursorUserList } from '../../components/CursorChat/CursorUserList';
import { CursorUserString } from "../../components/CursorChat/CursorUserString";
import { CursorGuideStatus } from "../../components/CursorChat/CursorGuideStatus";
//import appConstant from '../../utils/appConstant';

class Humanactivities extends Component {
  constructor(props) {
    super(props);
    var fromEventPage = false;
    var backgroundImage = "";
    var toExhibition = false;
    var exhibitionName = "";
    if (props.history.location.state) {
      if (
        props.history.action !== "POP" &&
        props.history.location.state.fromEventPage
      ) {
        fromEventPage = true;
      }
      if (props.history.location.state.backgroundImage) {
        backgroundImage = props.history.location.state.backgroundImage;
      }
      if (props.history.location.state.toExhibition) {
        toExhibition = true;
        exhibitionName = props.history.location.state.exhibitionName;
      }
    }
    this.state = {
      isHovering: false,
      activeBgImage: "",
      imageLink: "",
      x: 0,
      y: 0,
      fromEventPage: fromEventPage,
      fromLink: false,
      backgroundImage: backgroundImage,
      secondPage: true,
      disableFunction: true,
      toExhibition: toExhibition,
      exhibitionName: exhibitionName,
      lineX: 0,
      lineY: 0,
      width: 0,
      exhibitionLink: "",
      footerSubtitle: "",
      workTitle: "",
      artistName: "",
      mobileInterface: false,
      showModal: false,
      showAboutPage: false,
      titleAnimation: "false",
      mobileOverlay: !!localStorage.getItem("mobileOverlay"),
      visitedSlugs: localStorage.getItem("visitedSlugs") || [],
    };
    this.enableChangeBg = this.enableChangeBg.bind(this);
    this.timer = setTimeout(this.enableChangeBg, 1000);
    this.changePageState = this.changePageState.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.subpageRedirect = this.subpageRedirect.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showAboutPage = this.showAboutPage.bind(this);
    this.arrowTop = React.createRef();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async componentDidMount() {
    const footerLogoMobile = document.getElementById("footer_logo--mobile");
    footerLogoMobile &&
      footerLogoMobile.addEventListener("click", this.subpageRedirect);
    let paramsName = this.props.history.location.pathname.slice(1);

    this.props.mouseOut();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    this.props.getPanelData("/" + paramsName);
    this.props.getMainData();
    //this.props.getRoomData();

    /*await new Promise(resolve => {
            //this.props.getPageDataBySlug(paramsName, resolve);
            //this.props.getHumanData();
            //this.props.getExhibitionsData();
            //this.props.getQueendomData();
            this.props.getPanelData('/' + paramsName);
            this.props.getMainData();
        });*/

    //this.state.titleAnimation = localStorage.getItem("titleAnimation");
    this.state.titleAnimation = true;

    if (window.innerWidth > 600) {
      this.setState({
        lineX: 200,
      });
    }
  }

  changePageState() {
    setTimeout(() => {
      document.getElementsByClassName(
        "left-main-div"
      )[0].style.transitionTimingFunction = "cubic-bezier(.35,-0.4,.8,.7)";
      document.getElementById("imagesSlide").style.transitionTimingFunction =
        "cubic-bezier(.35,-0.4,.8,.7)";
      this.setState({
        secondPage: false,
      });
    }, 100);
  }

  subpageRedirect = async () => {
    document.getElementsByClassName("page")[0].classList.add("exit-Up");
    this.props.getContributionData(this.state.exhibitionLink);
    this.state.exhibitionLink !== "" &&
      this.props.history.push({
        pathname: "/h/" + this.state.exhibitionLink,
        state: {
          toExhibition: this.state.toExhibition,
          exhibitionName: this.state.exhibitionName,
        },
      });
  };

  exhibitionRedirect = (exhibitionPanel) => {
    this.setState({
      showModal: false,
    });
    exhibitionPanel = exhibitionPanel.replace(/^\//, "");

    exhibitionPanel &&
      this.props.history.push({
        pathname: exhibitionPanel,
      });
  };

  async componentDidUpdate(prevProps) {
    if (
      this.props.userStatus?.joinedGuide &&
      this.props.guideStatus.guideRoom !== prevProps.guideStatus.guideRoom
    ) {
      let newGuideRoom = this.props.guideStatus.guideRoom ?? false;
      //console.log('room changed to:' + newGuideRoom);
      if (newGuideRoom && newGuideRoom.includes("/h/")) {
        this.humanPostPage(false, newGuideRoom.replace("/h/", ""));
      }
    }
    if (
      this.props.history.action === "POP" &&
      String(prevProps.panelData) === ""
    ) {
      let paramsName = this.props.history.location.pathname.slice(1);
      this.props.getPanelData("/" + paramsName);
      //this.props.getHumanData();
      //this.props.getExhibitionsData();
      //this.props.getQueendomData();
    }
    /* @FIXME: workaround for loading the lines after you have entered the subpage directly and then pressed the logo */
    if (
      this.props.history.action === "PUSH" &&
      String(prevProps.panelData) === ""
    ) {
      let paramsName = this.props.history.location.pathname.slice(1);
      this.props.getPanelData("/" + paramsName);
      //this.props.getHumanData();
      //this.props.getExhibitionsData();
      //this.props.getQueendomData();
    }
    if (
      this.props.history.action === "POP" &&
      String(prevProps.panelData) === ""
    ) {
      let paramsName = this.props.history.location.pathname.slice(1);
      this.props.getPanelData("/" + paramsName);
      /*await new Promise(resolve => {
                this.props.getPageDataBySlug(paramsName, resolve);
            });*/
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  enableChangeBg() {
    this.setState({
      disableFunction: false,
    });
  }

  humanPostPage = async (e, slug) => {
    if (e) {
      e.preventDefault();
    }

    let visitedSlug = [];
    if (typeof localStorage["visitedSlugs"] !== "undefined") {
      visitedSlug = JSON.parse(localStorage["visitedSlugs"]);
    }

    visitedSlug.push(slug);
    localStorage["visitedSlugs"] = JSON.stringify(visitedSlug);

    this.props.getContributionData(slug);
    this.props.history.push({
      pathname: "/h/" + slug,
      state: {
        toExhibition: this.state.toExhibition,
        exhibitionName: this.state.exhibitionName,
        prevPath: this.props.location.pathname,
      },
    });
  };

  moveToolTip = (e, i) => {
    let tooltip = document.getElementById("Tooltip" + i);
    var tooltipWidth = tooltip.offsetWidth;
    // var boxWidth = (window.innerWidth - 40) / this.props.humanData.length;
    let offsetRight = window.innerWidth - e.clientX;
    let offsetLeft = e.clientX;

    if (tooltipWidth < offsetLeft && tooltipWidth + 40 < offsetRight) {
      this.setState({
        x: e.clientX + 20,
        y: e.clientY,
      });
    } else if (tooltipWidth > offsetLeft && tooltipWidth + 40 < offsetRight) {
      this.setState({
        x: e.clientX + 20,
        y: e.clientY,
      });
      tooltip.style.width = "";
    } else if (tooltipWidth < offsetLeft && tooltipWidth + 40 > offsetRight) {
      this.setState({
        x: e.clientX - tooltipWidth - 40,
        y: e.clientY,
      });
    } else {
      this.setState({
        x: e.clientX - tooltipWidth / 2,
        y: e.clientY,
      });
      tooltip.style.width = "auto";
    }
  };

  handleGalleryScroll = async (e) => {
    const galleryList = document.querySelector(".humanActivitiesMainDiv");
    const lineCursor = document
      .getElementById("lineCursor")
      .getBoundingClientRect();
    let items = [...document.querySelectorAll(".vertical-whiteLine")];
    let clones = [];

    if (!this.state.galleryClonesExecuted) {
      this.setState({
        galleryClonesExecuted: true,
      });
    }

    this.setState({
      mobileInterface: true,
    });

    items.forEach((item) => {
      const verticalLinePos = item.getBoundingClientRect();
      const targetId = item.attributes["id"].value.replace("verticalLine", "");
      const artistName =
        this.props.panelData.exhibitionContributions[targetId].artistName;
      const workTitle =
        this.props.panelData.exhibitionContributions[targetId].title;

      if (!this.state.galleryClonesExecuted) {
        let clone = item.cloneNode(true);
        clone.classList.add("clone");
        galleryList.appendChild(clone);
        clones.push(clone);
      }

      if (
        lineCursor.x - verticalLinePos.x < lineCursor.x < verticalLinePos.x
          ? verticalLinePos.width - 100
          : lineCursor.width
      ) {
        this.setState({
          imageLink: item.attributes["data-src"].value,
          exhibitionLink:
            this.props.panelData.exhibitionContributions[targetId].slug,
          workTitle: workTitle,
          artistName: artistName,
        });
      }
    });

    if (
      galleryList.offsetWidth + galleryList.scrollLeft >=
      galleryList.scrollWidth
    ) {
      galleryList.scrollLeft = galleryList.scrollWidth / 2 - 368;
    } else if (galleryList.scrollLeft <= 0) {
      galleryList.scrollLeft = galleryList.scrollWidth / 2;
    }
  };

  handleArrowShow = () =>
    this.arrowTop.current.classList.toggle("arrow-top--hidden");

  showTooltipChangeBg = async (e, i) => {
    this.setState({
      isHovering: !this.state.isHovering,
      activeBgImage: i,
    });

    let targetId = e.target.id;
    let isHovering = this.state.isHovering;

    if (!isHovering) {
      this.refs[targetId].classList.add("show-item");
    } else {
      this.refs[targetId].classList.remove("show-item");
    }
  };

  cursorLine = (e) => {
    if (window.screen.width > 800) {
      this.setState({
        lineX: e.clientX,
      });
    }
  };
  // clickHomeLink = async () => {
  //     document.getElementsByClassName('page')[0].classList.remove('exit-Left');
  //     document.getElementsByClassName('page')[0].classList.add('exit-Right');
  //
  //     await new Promise(resolve => {
  //         this.props.getPageDataBySlug('random-teaser', resolve)
  //     });
  //
  //     let currentPath = this.props.history.location.pathname;
  //     this.props.history.push({
  //         pathname: '/' + constants.INTRO_URL,
  //         state: {
  //             beforePath: currentPath,
  //             fromHumanPage: true
  //         }
  //     });
  // }

  showModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  showAboutPage = () => {
    this.setState({
      showAboutPage: !this.state.showAboutPage,
    });
  };

  render() {
    const { visitedSlugs } = this.state;
    if (this.props?.panelData?.length === 0) {
      return null;
    }

    let bgUrl = "";
    if (this.state.imageLink !== "") {
      bgUrl = this.state.imageLink;
    } else {
      if (this.state.backgroundImage !== "") {
        bgUrl = this.state.backgroundImage;
      } else if (this.props?.panelData?.exhibitionImage.length > 0) {
        bgUrl = "this.props.panelData.exhibitionImage[0].url";
      }
    }

    let transitionClass = "enter-Right";
    if (this.state.fromEventPage) {
      transitionClass = "enter-Down";
    } else if (this.state.fromLink) {
      transitionClass = "enter-Left exit-Up";
    }

    //this.props.humanData.sort((a, b) => a.acf.post_title.localeCompare(b.acf.post_title))

    let exhibitionName;
    let superScript;
    let exhibitionId;
    let exhibitionPos;
    let exhibitionImage;
    let seoTitle = this.props?.panelData?.seo?.title;
    let seoImageUrl = this.props?.panelData?.seo?.social?.facebook?.image?.url;
    let seoSlug = this.props?.panelData?.exhibitionPublicUrl;
    let seoDescription = this.props?.panelData?.seo?.description;

    /*
        //@TODO: unify with Footer & PanelFooter & httpProcessor and apply DRY
        let categoryMap = Object.values(constants.EXHIBITIONS_CATEGORY_IDS);
        let categoryUrls = Object.keys(constants.EXHIBITIONS_CATEGORY_IDS);
        let urlToMapIndex = categoryUrls.indexOf(window.location.pathname);
        let categoryIdToUse = constants.CATEGORY_ID;
        if( urlToMapIndex > -1 ){
            categoryIdToUse = categoryMap[urlToMapIndex];
        }

        this.props.categories && this.props.categories.forEach(category => {
            const {id, name} = category;
            if (categoryIdToUse === id) {
                const exhibitionCategoryName = name.split('-');
                const exhibitionData = this.props.exhibitionsData[0];

                exhibitionName = exhibitionCategoryName[0];
                superScript = exhibitionCategoryName[1];
                exhibitionId = exhibitionCategoryName[1].replace( /^\D+/g, '');
                exhibitionImage = exhibitionData && exhibitionData.acf.exhibition_panels[exhibitionId-1].exhibition_image;
            }
        })*/

    //new from here
    if (this.props?.panelData?.exhibitionImage?.length > 0) {
      exhibitionImage = this.props.panelData.exhibitionImage[0].url;
    }

    if (this.props?.panelData) {
      superScript = this.props.panelData?.exhibitionShortTitle
        ? this.props.panelData?.exhibitionShortTitle
        : this.props.panelData?.title;
      exhibitionId = this.props?.panelData?.id;
    }

    if (this.props?.mainData) {
      exhibitionName = this.props.mainData.title;
    }

    if (this.props?.panelData && this.props?.mainData) {
      exhibitionPos = this.props?.mainData?.children
        ?.map((object) => object.id)
        .indexOf(exhibitionId);
    }
    let paramsName = this.props.history.location.pathname.slice(1);
    return (
      <>
        <HelmetHeader
          title={seoTitle}
          seoSlug={seoSlug}
          description={seoDescription}
          imageUrl={seoImageUrl}
        ></HelmetHeader>

        <CursorGuideStatus />
        {this.state.titleAnimation !== true && (
          <div id="layout-lock" className="layout-lock" />
        )}
        <div
          className={
            "whatever-class home-page humanActivity-page page " +
            transitionClass
          }
        >
          {/*<HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}></HomeLink>*/}
          <div
            className="headerDiv"
            onScroll={(e) => this.handleGalleryScroll(e)}
            onTouchStart={() => this.handleArrowShow()}
            onTouchEnd={() => this.handleArrowShow()}
          >
            <div className="main-border position-relative animation-content h-100">
              <div className="slide-home h-100">
                <div
                  id="linkData"
                  className={
                    "left-main-div main-part " +
                    (this.state.secondPage ? "secondLinkDiv" : "FistLinkDiv")
                  }
                >
                  <div id="linkdiv">{/* {this.categories()} */}</div>
                </div>
                <div
                  id="imagesSlide"
                  className={
                    "sliderStyle h-100 human-part " +
                    (this.state.secondPage
                      ? "secondImagesSlide"
                      : "FistImagesSlide")
                  }
                >
                  {/* 
                            <div id="imagesSlide" className={"sliderStyle h-100 human-part " + (this.state.secondPage ? 'secondImagesSlide' : 'FistImagesSlide')} onMouseMove={(e) => this.cursorLine(e)}
                            ></div>   */}
                  <div className="h-100">
                    {!this.state.secondPage ? (
                      <div
                        style={{
                          backgroundImage: `url(${bgUrl})`,
                          backgroundSize: "cover",
                        }}
                        className="imgStyle"
                      ></div>
                    ) : (
                      <div className="h-100 position-relative">
                        {this.state.width <= 800 && (
                          <div
                            id="humanActivity-page__image"
                            className="humanActivity-page__image humanActivity-page__image--active"
                            style={{ backgroundImage: `url(${bgUrl})` }}
                          >
                            {" "}
                          </div>
                        )}
                        <div
                          id="parentDiv"
                          className="d-flex humanActivitiesMainDiv"
                        >
                          {exhibitionImage &&
                            this.state.width > 800 &&
                            this.state.titleAnimation === true && (
                              <div
                                className={`humanActivity-page__image humanActivity-page__image--active humanActivity-page__image--permanent 
                                                            ${
                                                              this.state
                                                                .showModal ||
                                                              this.state
                                                                .showAboutPage
                                                                ? "humanActivity-page__image--blur"
                                                                : ""
                                                            }`}
                                style={{
                                  backgroundImage: `url(${exhibitionImage})`,
                                }}
                              />
                            )}
                          {this.props?.panelData?.exhibitionContributions
                            .length > 0 ? (
                            this.props.panelData.exhibitionContributions.map(
                              (data, i) => {
                                return (
                                  <React.Fragment key={"contrib" + i}>
                                    {this.state.width > 800 ? (
                                      <>
                                        {data.backgroundImage.length > 0 && (
                                          <>
                                            <div
                                              id="humanActivity-page__image"
                                              className={
                                                this.state.isHovering &&
                                                this.state.activeBgImage === i
                                                  ? "humanActivity-page__image humanActivity-page__image--active"
                                                  : "humanActivity-page__image"
                                              }
                                              style={{
                                                backgroundImage: `url(${data.backgroundImage[0].url})`,
                                              }}
                                              key={"hoverHuman" + i}
                                            />

                                            <a
                                              href={"/h/" + data.slug}
                                              onMouseOver={(e) => {
                                                this.showTooltipChangeBg(e, i);
                                                this.props.setCursorActive(
                                                  true
                                                );
                                              }}
                                              onMouseLeave={(e) => {
                                                this.showTooltipChangeBg(e, i);
                                                this.props.setCursorActive(
                                                  false
                                                );
                                              }}
                                              onMouseMove={(e) =>
                                                this.moveToolTip(e, i)
                                              }
                                              id={"verticalLine" + i}
                                              className={
                                                visitedSlugs.includes(data.slug)
                                                  ? "vertical-whiteLine visited"
                                                  : "vertical-whiteLine"
                                              }
                                              onClick={(e) =>
                                                this.humanPostPage(e, data.slug)
                                              }
                                              key={"humanVertical" + i}
                                              data-src={
                                                data?.backgroundImage[0]?.url
                                              }
                                            >
                                              <CursorUserString
                                                room={data?.slug}
                                              ></CursorUserString>
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {data.backgroundImage.length > 0 && (
                                          <>
                                            <div
                                              id={"verticalLine" + i}
                                              className="vertical-whiteLine"
                                              onClick={() =>
                                                this.subpageRedirect()
                                              }
                                              key={"humanVertical" + i}
                                              data-src={
                                                data.backgroundImage[0].url
                                              }
                                            ></div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {this.state.width > 800 &&
                        this.props?.panelData?.exhibitionContributions.length >
                          0 ? (
                          this.props?.panelData?.exhibitionContributions.map(
                            (data, i) => {
                              return (
                                <div
                                  id={"Tooltip" + i}
                                  key={"humantip" + i}
                                  className={
                                    (data?.labelImage[0]?.url
                                      ? "tooltip-content tooltip-content--big"
                                      : "tooltip-content") +
                                    (this.props?.meMessage?.length < 1 &&
                                    this.props?.localMessage?.length < 1 &&
                                    !this.props?.isTyping &&
                                    !this.props?.showInput
                                      ? " tooltip--visible"
                                      : " tooltip--hidden")
                                  }
                                  ref={"verticalLine" + i}
                                  style={{
                                    top: this.state.y,
                                    left: this.state.x,
                                  }}
                                  // onMouseMove={(e) => this.moveToolTip(e)}
                                  // onLoad={(e) => this.moveToolTip(e)}
                                >
                                  {data?.labelImage?.length > 0 && (
                                    <div className="tooltip__img">
                                      <img
                                        src={data.labelImage[0].url}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <span>{data?.title}</span>
                                  <span>{data?.artistName}</span>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <div></div>
                        )}

                        <div
                          className={
                            this.state.mobileInterface
                              ? "mobile-navigation--active"
                              : "mobile-navigation"
                          }
                        >
                          {this.state.workTitle && this.state.artistName && (
                            <div
                              onClick={() => this.subpageRedirect()}
                              className="tooltip-content"
                            >
                              <span>{this.state.workTitle}</span>
                              <span>{this.state.artistName}</span>
                            </div>
                          )}
                          <div
                            ref={this.arrowTop}
                            className="arrow-top"
                            onClick={() => this.subpageRedirect()}
                          >
                            <img
                              src={require("../../assets/arrow.svg")}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* 
                        <span id="lineCursor" className="lineCursor" style={{left: this.state.lineX + 'px' }}/>
                                */}
              {paramsName === "panel3" ? (
                <img
                  src={require("../../assets/logos/panel-3-logo.png")}
                  alt="biennale-logo"
                  className="biennale-logo"
                />
              ) : (
                <img
                  src={require("../../assets/biennale-logo.svg")}
                  alt="biennale-logo"
                  className="biennale-logo"
                />
              )}
            </div>
          </div>
          <Footer
            showAboutPage={this.showAboutPage}
            showNavigationPage={this.showModal}
            superScript={superScript}
            exhibitionName={exhibitionName}
          />
        </div>
        <Navigation
          show={this.state.showModal}
          handleShow={() => this.showModal()}
          exhibitionsData={this.props.exhibitionsData}
          mainData={this.props.mainData}
          handleExhibitionRedirect={this.exhibitionRedirect}
          exhibitionName={exhibitionName}
          superScript={superScript}
          exhibtionPos={exhibitionPos}
        />
        <AboutPage
          show={this.state.showAboutPage}
          exhibitionName={exhibitionName}
          handleShow={() => this.showAboutPage()}
          mainData={this.props.mainData}
        />

        {!this.state.mobileOverlay && <MobileOverlay />}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    //categories: state.categoriesData,
    //humanData: state.humanData,
    //pageData: state.pageData,
    //exhibitionsData: state.exhibitionsData,
    //queendomData: state.queendomData,
    panelData: state.panelData,
    mainData: state.mainData,
    contributionData: state.contributionData,
    meMessage: state.message,
    localMessage: state.message_local,
    isTyping: state.isTyping,
    showInput: state.showInput,
    guideStatus: state.guideStatus,
    userStatus: state.userStatus,
  };
}

const mapDispatchToProps = {
  mouseIn: baadActions.mousePosIn,
  mouseOut: baadActions.mousePosOut,
  //getHumanPostDataBySlug: baadActions.getHumanPostDataBySlug,
  //getHumanData: baadActions.getHumanData,
  //getPageDataBySlug: baadActions.getPageDataBySlug,
  //getExhibitionsData: baadActions.getExhibitionsData,
  //getQueendomData: baadActions.getQueendomData,
  getPanelData: baadActions.getPanelData,
  getMainData: baadActions.getMainData,
  getContributionData: baadActions.getContributionData,
  getRoomData: baadActions.getRoomData,
  setCursorActive: baadActions.setCursorActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Humanactivities);
