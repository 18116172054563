import React from "react";
import { Row, Col } from "react-bootstrap";
import Button from "../../components/Button/Button";

import "./exhibitionPost.scss";
import footerLogo from "../../assets/logo.svg";
import closeIcon from "../../assets/close-icon.png";
import thewrongbiennaleLogo from "../../assets/thewrongbiennale-logo.png";

const ExhibitionPost = ({ handleClose, show }) => {
  return (
    <div>
      {show && (
        <img
          src={closeIcon}
          alt=""
          onClick={handleClose}
          className="close-icon"
        />
      )}
      <div
        className={`${
          show
            ? "whatever-class exhibitionPost-Page page opacity-1"
            : "whatever-class exhibitionPost-Page page opacity-0"
        }`}
      >
        <div className="exhibitionPost-Page-wrapper">
          <div className="container">
            <div className="exhibitionPost-Page__header">
              <div></div>
              <h1 className="exhibitionPost-Page__title">
                Exorcizing the West
                <span className="exhibitionPost-Page__subtitle">ST 001</span>
              </h1>
            </div>
            <div className="exhibitionPost-Page__body">
              <div className="exhibitionPost-Page__lists">
                <div className="exhibitionPost-Page__list">
                  <div className="p-0">
                    <p>A String</p>
                  </div>
                  <div className="p-0">
                    <ul>
                      <li className="exhibitionPost-Page__list-item">
                        Initiated and curated
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        by Jonathan Touitou
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        {" "}
                        01.11.21 - 01.03.22
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="exhibitionPost-Page__list">
                  <div className="p-0">
                    <p>Participants</p>
                  </div>
                  <div className="p-0">
                    <ul>
                      <li className="exhibitionPost-Page__list-item">
                        Simha Shirman
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Eran Naveh
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Itshak Danziger
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Pieter Bruegel the Elder
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Guy Avital
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Axel Petersen
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Sharon Fadida
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Nimrod Gershoni
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Yaron Attar
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Eli Petel
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Maya Attoun
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Esther Schneider
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Michal Helfman
                      </li>
                      <li className="exhibitionPost-Page__list-item">
                        Assaf Shaham
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="exhibitionPost-Page__list">
                  <div className="p-0 d-none d-lg-block">
                    <p></p>
                  </div>
                  <div className="p-0">
                    <ul>
                      <li className="exhibitionPost-Page__list-item exhibitionPost-Page__list-item--light">
                        Graphic design:
                      </li>
                      <li className="exhibitionPost-Page__list-item exhibitionPost-Page__list-item--light">
                        Quentin Gaudry
                      </li>
                    </ul>
                  </div>
                  <div className="p-0 d-none d-lg-block">
                    <p></p>
                  </div>
                  <div className="p-0">
                    <ul>
                      <li className="exhibitionPost-Page__list-item exhibitionPost-Page__list-item--light">
                        Development:
                      </li>
                      <li className="exhibitionPost-Page__list-item exhibitionPost-Page__list-item--light">
                        Coding Mice
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mb-5 mb-md-0">
                <p className="exhibitionPost-Page__text">
                  In the course of a century, imperialism has mutated into
                  "cultural” policies and expanded the art world to the global
                  scale market we know today.
                  <br />
                  Legitimized by often bias historiography, those policies
                  succeeded in keeping non-Western cultures under the label of
                  mere "influence" and prompted the looting of their artefacts,
                  regularly with the help of armies, which now populate
                  Occidental museums.
                  <br />
                  Now trends, these policies further spread by the 'kind' help
                  of artists performing the rituals themselves, while excluding
                  their immediate context. 'Tirailleurs'* working on account of
                  galleries, museums, foundations, or private collections hoping
                  to include our work, often being fully aware of the terms in
                  which we do so but compelled by the academy to believe in our
                  rescuing by Western culture from the now impoverished local
                  one. More often conscious than not, this choice implies the
                  acceptance of the museum setting, or rather the discipline it
                  was initially designed for, and facilitates their monitoring
                  of our local cultures, thus relegating ourselves to peripheral
                  bodies.
                  <br />
                </p>
                <p className="exhibitionPost-Page__text">
                  *A tirailleur (French), in the Napoleonic era, was a type of
                  light infantry trained to skirmish ahead of the main columns.
                  Subsequently, tirailleurs were used by the French Army as a
                  designation for indigenous infantry recruited in the French
                  colonial territories during the 19th and 20th centuries, or
                  for metropolitan units serving in a light infantry role.
                </p>
              </div>
            </div>
            <div className="d-flex d-md-none align-items-center justify-content-end">
              <a href="https://thewrong.org" target="_blank">
                <img
                  src={thewrongbiennaleLogo}
                  alt=""
                  className="mr-5 exhibitionPost-Page__logo"
                />
              </a>
              <img
                src={footerLogo}
                alt=""
                className="exhibitionPost-Page__logo"
              />
            </div>
          </div>
        </div>
      </div>
      {show && (
        <div className="exhibitionPost-Page__btn-wrapper">
          <a onClick={handleClose}>
            <Button btnText="Visit Exhibition" />
          </a>
        </div>
      )}
    </div>
  );
};

export default ExhibitionPost;
