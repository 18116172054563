export default {
    BASE_URL: "https://dev-api.str-ing.org/wp-json/wp/v2/",
    BASE_URL2: "https://dev-api.str-ing.org/wp-json/cm/",
    CATEGORY_ID: 34,
    EXHIBITIONS_CATEGORY_IDS: {
        "/panel1": 35,
        "/panel2": 36,
        "/panel3": 37,
        "/panel4": 38,
        "/panel5": 39,
        "/panel6": 40,
        "/panel7": 41,
        "/panel8": 42,
    },
    INTRO_URL: 'intro-queendom',
    API_URL_FROM_ENV: 'https://editor.str-ing.org/api',
    CRAFT_EXHIBITION_ID: 12,
}
